import React, { FC } from "react";
import Layout from "../components/Layout/Layout";
import CaseStudyMain from "../components/CaseStudyUiComponents/CaseStudyMain";
import { graphql } from "gatsby";
import { SEO } from "../components/seo";

type Props = {
  location: any;
  data: any;
  pageContext: any;
};

const CaseStudyPage: FC<Props> = ({ location, data, pageContext }) => {
  return (
    <div>
      <Layout location="/blog">
        <SEO
          title="Case studies"
          description="Take a deep dive into unique success stories, and get a better vision into the innovative solutions we build to help organizations reach the next level."
          type="website"
        />
        <>
          <CaseStudyMain data={data} pageContext={pageContext} />
        </>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query AllSanityCaseStudyQuery2($skip: Int, $limit: Int) {
    allSanityCaseStudy(
      skip: $skip
      limit: $limit
      sort: { order: DESC, fields: _createdAt }
    ) {
      edges {
        node {
          _rawMainImage(resolveReferences: { maxDepth: 20 })
          _rawTestimonialImage
          _rawSolutionImage
          _rawChallengeImage
          _rawBenefitsImage
          altBenefitsImage
          altChallengeImage
          altSolutionImage
          altTestimonialImage
          altMainImage
          id
          title
          titleDE
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          altMainImage
          caseStudyDescription {
            children {
              text
            }
          }
          caseStudyDescriptionDe {
            children {
              text
            }
          }
        }
      }
    }

    allSanityCaseStudyTag {
      edges {
        node {
          name
        }
      }
    }
  }
`;

export default CaseStudyPage;
